import React, { Component } from 'react';
import { withStyles, Icon, Typography, Tooltip, Button, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import {connect} from 'react-redux';
import {FusePageSimple} from '../../../../../@fuse';
import * as actions from '../../../../store/crm/manager/actions';
import * as actions2 from '../../../../store/crm/actions';
// import ReactTable from "react-table";
// import RouteToDebnr from '../../../../components/Functions/RouteToDebnr';
import EditNewLeadsUserDialog from './EditNewLeadsUserDialog';
import {withTranslation} from 'react-i18next';
// import _, { isUndefined } from 'lodash';
// import moment from 'moment';
import { getAzureId } from '../../../Auth/Auth';
import ExportNewLeadsTable from './ExportNewLeadsTable';

const styles = theme => ({

});

const initialForm = {
    email: '',
    debnr: '',
    debnaam: '',
    debadres: '',
    debpc: '',
    debplaats: '',
    debcont: '',
    debtfnr: '',
    debmobiel: '',
    email2: '',
    lvnaamdb: '',
    lvadresdb: '',
    lvpcdb: '',
    lvplaatsdb: '',
    password: '',
    btwnrdeb: '',
    // acctmngr: '',
    // btwok: '1',
    transcod: '',
    template: '',
    findebnr: '',
    kvk: '',
    azure_id: getAzureId(),
    acctmngR_NOTE: '',
    vieS_VALID: false,
    vieS_NAME: '',
    vieS_ADDRESS: '',
    vieS_VAT: '',
    countEmails: '',
    countVAT: '',
    debtext: '',
    debfingrp: '',
};

class ExportNewLeads extends Component {

    state = {
        form: {
            ...initialForm
        },
        exportNewLeads: [],
        exportNewLeadsReady: false,
        editDialogOpen: false,
        checkedDelivery: false,
        refreshedOnEdit: false,
        getFinalData: [],
        filterNewleads: 'all',
    }

    componentDidMount() {
        this.props.onFetchExportNewLeads();
        this.props.onFetchAllTemplatesNewLeads();
    }
    

    componentDidUpdate(prevProps) {
        if((this.props.exportnewleads.length !== 0 && !this.state.exportNewLeadsReady)
            || (this.props.generatedExportnewleads && JSON.stringify(this.props.exportnewleads) !== JSON.stringify(prevProps.exportnewleads))
            || (JSON.stringify(this.props.exportnewleads) !== JSON.stringify(prevProps.exportnewleads))
            ) {
                if(this.state.editDialogOpen) {
                    this.setState({
                        exportNewLeads: this.props.exportnewleads, exportNewLeadsReady: true, refreshedOnEdit: true,
                        form: {...initialForm, ...this.props.exportnewleads.find(deb => deb.id === this.state.form.id)}
                    })
                } else {
                    this.setState({exportNewLeads: this.props.exportnewleads, exportNewLeadsReady: true, refreshedOnEdit: true});
                }
        }
    }

    handleEdit = id => {
        this.setState({editing: true });
        const debiteur = this.props.exportnewleads.find(deb => deb.id === id);
        this.setState({form: {...initialForm, ...debiteur, 
            // btwok: debiteur.btwok       ? debiteur.btwok : '',
            kvk: debiteur.kvk           ? debiteur.kvk : '',
            btwnrdeb: debiteur.btwnrdeb ? debiteur.btwnrdeb : '',
            // transcod: debiteur.transcod ? debiteur.transcod : '',
            template: debiteur.template ? debiteur.template : ''
        }});
        this.props.onSetEditNewLeadFalse();
        this.setState({editDialogOpen: true, refreshedOnEdit: false});
    }

    handleSwitch = event => {
        this.setState({ checkedDelivery: event.target.checked })
        // console.log(this.state.checkedDelivery);
    }

    handleCreate = () => {
        this.setState({editing: false, form: {...initialForm}, checkedDelivery: false});
    }

    handleChange = event => {
        this.setState({
        form: {...this.state.form, [event.target.name]: event.target.value},
        })
    }

    handleonBlur = event => {
        if(event.target.name === 'btwnrdeb'){
            this.setState({
                form: {...this.state.form, [event.target.name]: event.target.value.replace(/[^A-Z0-9]/ig, "")},
            })
        }
    }

    handleEditDialogOpen = event => {
        this.setState({editDialogOpen: true});
    }

    handleEditDialogClose = () => {
        this.setState({editDialogOpen: false});
    }

    handleNewLeadCheck = (row) => {
        const {exportNewLeads} = this.state;
        const clickedLeadIndex = exportNewLeads.findIndex(lead => lead.id === row.id);

        const updatedLead = {...exportNewLeads[clickedLeadIndex], checked: row.checked ? !row.checked : true};
        // console.log(updatedLead);
        const copiedLeads = [...exportNewLeads];
        copiedLeads[clickedLeadIndex] = updatedLead;
        // console.log(copiedLeads);
        this.setState({exportNewLeads: copiedLeads});
    }

    getNewLeadsToExport = () => {
        const filteredLeads = this.state.exportNewLeads.filter(lead => lead.checked);
        // const filteredLeads = this.state.exportNewLeads.filter(lead => lead.checked).map(lead => lead.email);
        // console.log(filteredLeads);
        return filteredLeads;
    }

    
    afterCheckVies = ( viesData ) => {
        this.setState({ form: {
            ...this.state.form,
            vieS_VALID: viesData.valid,
            vieS_NAME: viesData.debnaam,
            vieS_ADDRESS: viesData.debAddress,
            vieS_VAT: viesData.vat, 
        } })
    }

    setFilterNewleads = (value) => {
        this.setState({filterNewleads: value});
    }
    
    getFinalData = () => {
        const { filterNewleads, exportNewLeads } = this.state;
        // console.log(filterNewleads);
        if(filterNewleads === 'all') {
            return exportNewLeads;
        }
        else if(filterNewleads === '1') {
            return exportNewLeads.filter(newLead => newLead.company_id1 === 1);
        }
        else if(filterNewleads === '2') {
            return exportNewLeads.filter(newLead => newLead.company_id1 === 2);
        }
        else if(filterNewleads === '3') {
            return exportNewLeads.filter(newLead => newLead.company_id1 === 3);
        }
        else if(filterNewleads === '4') {
            return exportNewLeads.filter(newLead => newLead.company_id1 === 4);
        }
        else if(filterNewleads === '5') {
            return exportNewLeads.filter(newLead => newLead.company_id1 === 5);
        }
    }
    render() {
        const {t, AllTemplatesNewLeads} = this.props;
        const { form, checkedDelivery } = this.state;
        // console.log('rendering export...');
        
        // console.log(AllTemplatesNewLeads);
        // var icrmusers = [
        //     {"azureId":"8172c618-69ca-4d09-908c-b7d1b5d31710","name":"iCRM Jo10 Coslosquet"},
        //     {"azureId":"8172c618-69ca-4d09-908c-b7d1b5d31720","name":"iCRM Pierre20 Michel"}
        // ];

        const companylist = [
            {key: 'all', value: 'All'},
            {key: '1', value: 'VDP'},
            {key: '2', value: 'DEXX Drive'},
            {key: '3', value: 'GF'},
            {key: '4', value: 'HBX DECO'},
            {key: '5', value: 'GB'}
        ]
    
        return (
            <>
            {this.state.editDialogOpen ? 
                <EditNewLeadsUserDialog
                    dialogOpen={this.state.editDialogOpen}
                    onClose={this.handleEditDialogClose}
                    onSave={this.onSaveDialog}
                    onDelete={this.onDeleteDialog}
                    newLeadForm={form}
                    allTemplates={AllTemplatesNewLeads}
                    handleChange={this.handleChange}
                    handleonBlur={this.handleonBlur}
                    users={AllTemplatesNewLeads}
                    handleSwitch={this.handleSwitch}
                    afterDelete={this.handleEditDialogOpen}
                    checkedDelivery={checkedDelivery}
                    afterCheckVies={this.afterCheckVies}
                /> : null
            }
            <FusePageSimple
                header={
                    <div className="flex flex-1 items-center justify-between p-24">
                        <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                <Icon className="text-18" color="action">home</Icon>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">iCRM</Typography>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">Manager</Typography>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">{t("ASSIGNNEWLEADS")}</Typography>
                            </div>
                            <Typography variant="h6">{'Export ' + t("LEADS")}</Typography>
                        </div>
                    <div className="flex items-end">
                        <FormControl className="ml-20">
                            <InputLabel id="land-label">{t("COMPANY")}</InputLabel>
                            <Select
                                className="pr-20"
                                id="land-select"
                                value={this.state.filterNewleads}
                                onChange={(event) => this.setFilterNewleads(event.target.value)}
                            >
                                {companylist.map(row => (
                                    <MenuItem key={row.key} value={row.key}>{row.value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Tooltip title="Download CSV to import in Exact">
                            <Button className="ml-20" variant="outlined" style={{color: '#ef6c00', border: '1px solid #ef6c00'}} onClick={() => this.props.onGenerateExactExportNewLead(this.getNewLeadsToExport())}>
                                <Icon>cloud_download</Icon> <span className="ml-8">Exact</span>
                            </Button>
                        </Tooltip>
                        <Tooltip title={'Download Excel to import in Florisoft'}>
                            <Button className="ml-20" variant="outlined" style={{color: '#27c24c', border: '1px solid #27c24c'}} onClick={() => this.props.onGenerateExportNewLead(this.getNewLeadsToExport())}>
                                <Icon>cloud_download</Icon> <span className="ml-8">Florisoft</span>
                            </Button>
                        </Tooltip>
                    </div>
                    </div>
                }
                content={
                    <ExportNewLeadsTable 
                        // exportnewleads={exportnewleads}
                        exportnewleads={this.getFinalData()}
                        handleNewLeadCheck={this.handleNewLeadCheck}
                        handleEdit={this.handleEdit}
                    />
                }
            />
            </>
            );

    }
}

const mapStateToProps = state => {
    return {
        exportnewleads: state.manager.exportnewleads,
        filteredLeads: state.manager.filteredLeads,
        generatedExportnewleads: state.manager.generatedExportnewleads,
        isEditedNewLead: state.manager.isEditedNewLead,
        AllTemplatesNewLeads: state.customer.AllTemplatesNewLeads
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchAllTemplatesNewLeads: () => dispatch(actions2.FetchdbAllTemplatesNewLeads()),
        onFetchExportNewLeads: () => dispatch(actions.fetchExportNewLeads()),
        onGenerateExportNewLead: (filteredLeads) => dispatch(actions.GenerateExportNewLead(filteredLeads)),
        onGenerateExactExportNewLead: (filteredLeads) => dispatch(actions.GenerateExactExportNewLead(filteredLeads)),
        onSetEditNewLeadFalse: () => dispatch(actions.setEditNewLeadFalse()),

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation()(ExportNewLeads)));
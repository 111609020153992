import React, { Component } from 'react'
import { Icon, Typography, Checkbox, Tooltip, IconButton, Link } from '@material-ui/core';
import {FuseAnimateGroup} from '../../../../../@fuse';
import ReactTable from "react-table";
import {withTranslation} from 'react-i18next';
import moment from 'moment';

class ExportNewLeadsTable extends Component {
    shouldComponentUpdate(nextProps, nextState) {
        const shouldUpdate = JSON.stringify(this.props.exportnewleads) !== JSON.stringify(nextProps.exportnewleads);
        return shouldUpdate; 
    }

    render() {
        const {exportnewleads, t} = this.props;
        return (
            <FuseAnimateGroup animation="transition.slideUpIn" delay={300}>
                        <div className="flex flex-wrap w-full">
                            <ReactTable
                                className="w-full -striped -highlight border-0"
                                data={exportnewleads}
                                // rowHeight={80} 
                                columns={[
                                    {
                                        Header    : "Export",
                                        accessor: "checked",
                                        className: "justify-center",
                                        width: 50,
                                        Cell: row => row.original.fS_EXPORTED === "" || row.original.fS_EXPORTED === null || row.original.fS_EXPORTED === 0  ? 
                                            <Checkbox
                                                checked={row.value}
                                                onChange={() => this.props.handleNewLeadCheck(row.original)}
                                                value={row.value}
                                                inputProps={{
                                                'aria-label': 'primary checkbox',
                                                }}
                                            />
                                        :
                                        <Tooltip title={"Exported on : " + moment(row.original.fS_EXPORTED_DATE).format('DD-MM-YYYY @ HH:mm')}>
                                            <IconButton>
                                                <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    },
                                    {
                                        Header    : t("EDIT"),
                                        className: "justify-center",
                                        width: 50,
                                        Cell: row => (
                                        <Tooltip title={t("EDIT")}>
                                            <IconButton aria-label="Edit" onClick={() => this.props.handleEdit(row.original.id)}>
                                                <Icon>edit</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        )
                                    },
                                    {
                                        Header    : "Company",
                                        accessor  : "companY_ID",
                                        filterable: true,
                                        className: "justify-center",
                                        width: 100,
                                        Cell: row => row.value === 2 ? 
                                        <Tooltip title={"Dexx Drive"}>
                                            <Typography color="inherit">DD</Typography>
                                        </Tooltip>
                                        :
                                        row.value === 3 ? 
                                        <Tooltip title={"Greenflor"}>
                                            <Typography color="inherit">GF</Typography>
                                        </Tooltip>
                                        :
                                        row.value === 4 ? 
                                        <Tooltip title={"Heembloemex Deco"}>
                                            <Typography color="inherit">HBX Deco</Typography>
                                        </Tooltip>
                                        :
                                        row.value === 5 ? 
                                        <Tooltip title={"Green Bloom"}>
                                            <Typography color="inherit">GB</Typography>
                                        </Tooltip>
                                        :
                                        <Tooltip title={"Van der Plas Flowers & Plants"}>
                                            <Typography color="inherit">VDP</Typography>
                                        </Tooltip>
                                    },
                                    {
                                        Header    : "Debnr",
                                        accessor  : "debnr",
                                        filterable: true,
                                        className: "justify-center",
                                        width: 70,
                                        Cell: row => row.value === "" || row.value === null ? 
                                        <Tooltip title={row.original.suggesteD_DEBNR === null ? "" : "Last debnr : " + row.original.suggesteD_DEBNR}>
                                            <Icon className="text-18" color="error">clear</Icon> 
                                        </Tooltip>
                                        :
                                        <Tooltip 
                                        title={
                                          <React.Fragment>
                                            <Typography color="inherit">DEBNR : {row.value}</Typography>
                                          </React.Fragment>
                                        }>
                                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                                        </Tooltip>
                                    },
                                    {
                                        Header    : "FinDebnr",
                                        accessor  : "findebnr",
                                        filterable: true,
                                        className: "justify-center",
                                        width: 70,
                                        Cell: row => row.value === "" || row.value === null ? 
                                        // <Tooltip title="Last debnr">
                                            <Icon className="text-18" color="error">clear</Icon> 
                                        // </Tooltip>
                                        :
                                        <Tooltip 
                                        title={
                                          <React.Fragment>
                                            <Typography color="inherit">Financieel DEBNR : {row.value}</Typography>
                                          </React.Fragment>
                                        }>
                                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                                        </Tooltip>
                                    },
                                    {
                                        Header    : t("NAME"),
                                        accessor  : "name",
                                        filterable: true,
                                        // className: "justify-center",
                                        width: 150,
                                        Cell: row => (
                                            <Link onClick={() => this.props.handleEdit(row.original.id)} style={{color: "#ef6c00", cursor: "pointer"}}>
                                                {row.original.name}
                                            </Link>
                                        )
                                    },
                                    {
                                        Header    : t("NOTE"),
                                        accessor  : "acctmngR_NOTE",
                                        className: "justify-center",
                                        width: 70,
                                        Cell: row => row.original.acctmngR_NOTE === '' ? 
                                        null 
                                        : 
                                        (
                                        <Tooltip title={row.value}>
                                            <IconButton aria-label="Comment">
                                                <Icon>comment</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        )
                                    },
                                    {
                                        Header    : "Email",
                                        accessor  : "email",
                                        // className: "left",
                                        width: 70,
                                        filterable: true,
                                        Cell: row => row.value === "" || row.value === null ? 
                                        <Tooltip title={'Email'}>
                                            <Icon className="text-18" color="error">clear</Icon> 
                                        </Tooltip>
                                        :
                                        <><Tooltip title={row.value}>
                                            <IconButton aria-label="Email">
                                                <Icon>email</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        {row.original.countEmails > 1 ? <span style={{color: 'orange'}}>{row.original.countEmails}</span> : null}
                                        </>
                                    },
                                    {
                                        Header    : "N " + t("VAT"),
                                        accessor  : "btwnrdeb",
                                        filterable: true,
                                        className: "justify-center",
                                        width: 70,
                                        Cell: row => row.value === "" || row.value === null ? 
                                        <Tooltip title={'No ' + t("VAT")}>
                                            <Icon className="text-18" color="error">clear</Icon> 
                                        </Tooltip>
                                        :
                                        <><Tooltip title={t("VAT") + " : " + row.value}>
                                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                                        </Tooltip>                        
                                        {row.original.countVAT > 1 ? <span style={{color: 'orange'}}>{row.original.countVAT}</span> : null}</>
                                    },
                                    {
                                        Header    : "N " + t("VAT"),
                                        accessor  : "btwnrdeb",
                                        filterable: true,
                                        className: "align-right",
                                        width: 170,
                                        Cell: row => row.original.vieS_VALID === true ?
                                        <><Tooltip title={"VIES Validated"}><Icon className="text-18" color="action" style={{color: 'green'}}>check_circle</Icon></Tooltip><Typography>&nbsp;{row.value}</Typography></>
                                        :
                                        (row.original.vieS_VALID === false && row.value.length > 0) ?
                                        <><Tooltip title={"Unvalid VIES"}><Icon className="text-18" color="action" style={{color: 'orange'}}>cancel</Icon></Tooltip><Typography>&nbsp;{row.value}</Typography></>
                                        :
                                        row.value
                                    },
                                    {
                                        Header    : "Template",
                                        accessor  : "template",
                                        filterable: true,
                                        className: "justify-center",
                                        width: 100,
                                        Cell: row => row.value === "" || row.value === null ? 
                                        <Tooltip title={t('CUSTOMER') + " template"}>
                                            <Icon className="text-18" color="error">clear</Icon> 
                                        </Tooltip>
                                        :
                                        // <Tooltip title={"Template : " + row.value + "<br>Validated on : " + moment(row.original.validateD_DATE).format('DD-MM-YYYY @ HH:mm')}>
                                        <Tooltip 
                                        title={
                                          <React.Fragment>
                                            <Typography color="inherit">Template : {row.value}</Typography>
                                            <em>{'Validated on : '}{moment(row.original.validateD_DATE).format('DD-MM-YYYY @ HH:mm')}</em>
                                          </React.Fragment>
                                        }>
                                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                                        </Tooltip>
                                    },
                                    {
                                        Header    : t("SHOP") + " " + t("NAME"),
                                        accessor  : "debnaam",
                                        filterable: true,
                                        className : "font-bold",
                                        minWidth: 200,
                                        // width: '20%',
                                    },
                                    {
                                        Header    : t("ADDRESS"),
                                        accessor  : "debadres",
                                        filterable: true,
                                        className: "justify-center",
                                        minWidth: 150,
                                        // width: '20%',
                                    },
                                    {
                                        Header    : t("ZIPCODE"),
                                        accessor  : "debpc",
                                        filterable: true,
                                        className: "justify-center",
                                        width     : 100,
                                    },
                                    {
                                        Header    : t("CITY"),
                                        accessor  : "debplaats",
                                        filterable: true,
                                        className: "justify-center",
                                        width     : 150,
                                    },
                                    {
                                        Header    : "Country",
                                        accessor  : "deblandcod",
                                        filterable: true,
                                        className: "justify-center",
                                        width: 120,
                                    },
                                    // {
                                    //     Header    : t("VALIDED"),
                                    //     className: "justify-center",
                                    //     width: 70,
                                    //     Cell: row => this.props.canBeValidated(row.original) === true ?
                                    //     (
                                    //     <Tooltip title={t("VALIDE")}>
                                    //         <IconButton aria-label="Edit" onClick={() => this.props.onValidateNewLead(row.original)} disabled={!this.canBeValidated(row.original)}>
                                    //             <Icon>send</Icon>
                                    //         </IconButton>
                                    //     </Tooltip>
                                    //     )
                                    //     :                                
                                    //     <IconButton aria-label="Edit" onClick={() => this.props.onValidateNewLead(row.original)} disabled={!this.canBeValidated(row.original)}>
                                    //         <Icon>send</Icon>
                                    //     </IconButton>
                                    // },
                                ]}
                                pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                defaultPageSize={100}
                                noDataText="No customers found"
                            />
                        </div>
                    </FuseAnimateGroup>
        )
    }
}

export default withTranslation()(ExportNewLeadsTable);

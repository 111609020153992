import React, { Component } from 'react';
import {
    Button, Dialog, DialogContent, Icon, Typography, Toolbar, AppBar, MenuItem, TextField,
    IconButton, Tooltip
} from '@material-ui/core';
// import _ from 'lodash';
import ReactTable from "react-table";
import { withTranslation } from 'react-i18next';
import { filterAll } from '../../../../components/Functions/FilterAll'

class NewLeadsMultipleVatDetailsDialog extends Component {

    //properties: accountManager, azureId, companyId, groupCode, email, groups, id, imageUrl, name
    state = {
        selectedUsers: [],
        favtemplates: [],
        userInput: ''
    };

    handleClose = () => {
        this.props.onClose();
        this.setState({ userInput: '' });
    }


    render() {
        const { dialogOpen, t } = this.props;
        const multiplevatdataleads = this.props.multiplevatdataleads;

        return (
            Object.keys(multiplevatdataleads).length > 0 ?
                <Dialog
                    classes={{
                        paper: "m-24"
                    }}
                    onClose={this.handleClose}
                    open={dialogOpen}
                    fullWidth
                    maxWidth="md"
                >
                    <AppBar position="static" elevation={1}>
                        <Toolbar className="flex w-full">
                            <Tooltip title="Go back">
                                <IconButton aria-label="Delete" size="small" color="secondary" onClick={this.handleClose}>
                                    <Icon>arrow_back</Icon>
                                </IconButton>
                            </Tooltip>
                            <Typography variant="subtitle1" color="inherit" className="ml-16">
                                {t('MULTIPLEVATDATALEAD')}
                            </Typography>
                        </Toolbar>
                    </AppBar>

                    <DialogContent classes={{ root: "p-24" }}>
                        <ReactTable
                            className="w-full -striped -highlight border-0"
                            data={multiplevatdataleads}
                            columns={[
                                {
                                    Header: "Company",
                                    accessor: "companY_ID",
                                    className: "justify-center",
                                    filterable: true,
                                    width: 100,
                                    Cell: row => row.value === 2 ? 
                                    <Tooltip title={"Dexx Drive"}>
                                        <Typography color="inherit">DD</Typography>
                                    </Tooltip>
                                    :
                                    row.value === 3 ? 
                                    <Tooltip title={"Greenflor"}>
                                        <Typography color="inherit">GF</Typography>
                                    </Tooltip>
                                    :
                                    row.value === 4 ? 
                                    <Tooltip title={"Heembloemex Deco"}>
                                        <Typography color="inherit">HBX Deco</Typography>
                                    </Tooltip>
                                    :
                                    row.value === 5 ? 
                                    <Tooltip title={"Green Bloom"}>
                                        <Typography color="inherit">GB</Typography>
                                    </Tooltip>
                                    :
                                    <Tooltip title={"Van der Plas Flowers & Plants"}>
                                        <Typography color="inherit">VDP</Typography>
                                    </Tooltip>
                                },
                                {
                                    Header: "Debnr",
                                    accessor: "debnr",
                                    className: "justify-center",
                                    filterable: true,
                                    width: 150,
                                    Cell: row => row.value === '' ? <i>creation process...</i> : row.value
                                },
                                {
                                    Header: t('NAME') + " " + t('SHOP'),
                                    accessor: "debnaam",
                                    filterable: true,
                                    width: '40%',
                                },
                                {
                                    Header: "Vat",
                                    accessor: "btwnrdeb",
                                    filterable: true,
                                    width: '40%',
                                },
                            ]}
                            pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                            defaultPageSize={10}
                            noDataText="No templates selected yet"
                        />
                    </DialogContent>
                </Dialog>
                : null
        );
    }
}

export default withTranslation()(NewLeadsMultipleVatDetailsDialog);

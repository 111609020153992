import React, { Component } from 'react';
import { withStyles, Icon, Typography, FormControl, Select, MenuItem, OutlinedInput, Tooltip, Button, IconButton, InputLabel } from '@material-ui/core';
import {connect} from 'react-redux';
import {FusePageSimple, FuseAnimateGroup} from '../../../../../@fuse';
import * as actions from '../../../../store/crm/manager/actions';
import ReactTable from "react-table";
// import RouteToDebnr from '../../../../components/Functions/RouteToDebnr';
import {withTranslation} from 'react-i18next';
import _, { } from 'lodash';
import moment from 'moment';
import { getAzureId, getCompany } from '../../../Auth/Auth';
import FavoriteUsersDialog from '../../Admin/Users/FavoriteUsersDialog';

const styles = theme => ({

});

class AssignNewLeads extends Component {

    state = {
        assignNewLeads: [],
        returnedNewLeads: [],
        iCRMusersNewLeads: [],
        FaviCRMusersNewLeads: [],
        insertedData: false,
        favoritesDialogOpen: false,
        fetchedReturnedNewLeads: false,
        getFinalData: [],
        // filterNewleads: 'all'
        filterNewleads: getCompany().toString()
    }

    componentDidMount() {
        this.props.onFetchAssignNewLeads();
        this.props.onFetchReturnedNewLeads();
        this.props.onFetchiCRMusersNewLeads();
        this.props.onFetchFaviCRMusersNewLeads();
    }

    componentDidUpdate() {
        if(!this.state.insertedData && this.props.fetchedAssignNewLeads && this.props.fetchedReturnedNewLeads) {
            const mergedLeads = this.props.returnednewLeads.concat(this.props.assignnewleads);
            this.setState({assignNewLeads: mergedLeads.map(l => ({...l, icrmuser: l.icrmuser ?? ''})), insertedData: true, returnedNewLeads: this.props.returnednewLeads});
        }
    }

    handleChange = (event, row) => {
        var icrmuser = event.target.value;
        let companyProp = row.companyid ? 'companyid' : 'companY_ID';
        var leads = _.cloneDeep(this.state.assignNewLeads);
        var leadIndex = leads.findIndex(lead => lead.email === row.email && lead[companyProp]?.toString() === row[companyProp]?.toString());
        var updatedLead = {...leads[leadIndex], icrmuser: icrmuser};
        leads[leadIndex] = updatedLead;
        this.setState({assignNewLeads: leads});
    }

    handleFavoritesDialogOpen = event => {
        // console.log(event);
        this.setState({favoritesDialogOpen: true});
    }

    handleFavoritesDialogClose = () => {
        this.setState({favoritesDialogOpen: false});
        this.props.onFetchFaviCRMusersNewLeads();
    }

    onSaveDialog = user => {
        // console.log(user);
        this.props.onAddFavUser(user);
    }
    
    onDeleteDialog = user => {
        // console.log(user);
        this.props.onDeleteFavUser({
            azureId: getAzureId(),
            favAzureId: user.fav_azure_id
        });
    }
    
    canBeSubmitted(row)
    {
        return row.icrmuser && row.icrmuser.length > 0
    }

    onActivateNewLead = (row) => {
        this.props.onActivateNewLead(row, this.onActivateNewLeadActivated);
    }

    onActivateNewLeadActivated = (row, activated) => {
        const leadIndex = this.state.assignNewLeads.findIndex(lead => lead.email === row.email);

        const activatedLead = {...this.state.assignNewLeads[leadIndex]};
        activatedLead.validated = activated;
        // activatedLead.azureId = getAzureId;

        const newLeads = _.cloneDeep(this.state.assignNewLeads);
        newLeads[leadIndex] = activatedLead;

        this.setState({assignNewLeads: newLeads});
    }

    setFilterNewleads = (value) => {
        this.setState({filterNewleads: value});
    }
    
    getFinalData = () => {
        const { filterNewleads, assignNewLeads } = this.state;
        // console.log(assignNewLeads);
        if(filterNewleads === 'all') {
            return assignNewLeads;
        }
        else if(filterNewleads === '1') {
            return assignNewLeads.filter(newLead => newLead.companyid === 1 || newLead.companY_ID === 1);
        }
        else if(filterNewleads === '2') {
            return assignNewLeads.filter(newLead => newLead.companyid === 2 || newLead.companY_ID === 2);
        }
        else if(filterNewleads === '3') {
            return assignNewLeads.filter(newLead => newLead.companyid === 3 || newLead.companY_ID === 3);
        }
        else if(filterNewleads === '4') {
            return assignNewLeads.filter(newLead => newLead.companyid === 4 || newLead.companY_ID === 4);
        }
        else if(filterNewleads === '5') {
            return assignNewLeads.filter(newLead => newLead.companyid === 5 || newLead.companY_ID === 5);
        }
    }

    render() {
        const {t} = this.props;
        const {favicrmusersnewleads} = this.props;
        // const {filterNewleads} = this.props;
        // console.log(returnednewLeads);
        
        // var icrmusers = [
        //     {"azureId":"8172c618-69ca-4d09-908c-b7d1b5d31710","name":"iCRM Jo10 Coslosquet"},
        //     {"azureId":"8172c618-69ca-4d09-908c-b7d1b5d31720","name":"iCRM Pierre20 Michel"}
        // ];

        const companylist = [
            {key: 'all', value: 'All'},
            {key: '1', value: 'VDP'},
            {key: '2', value: 'DEXX Drive'},
            {key: '3', value: 'GF'},
            {key: '4', value: 'HBX DECO'},
            {key: '5', value: 'GB'}
        ]
        // debugger;

        return (
            <>
            {/* <AssignNewLeadsUserDialog
                dialogOpen={this.state.favoritesDialogOpen}
                onClose={this.handleFavoritesDialogClose}
                onSave={this.onSaveDialog}
                onDelete={this.onDeleteDialog}
                users={icrmusersnewleads}
                favusers={favicrmusersnewleads}
            /> */}
            {this.state.favoritesDialogOpen && <FavoriteUsersDialog handleClose={this.handleFavoritesDialogClose} />}
            <FusePageSimple
                header={
                    <div className="flex flex-1 items-center justify-between p-24">
                        <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                <Icon className="text-18" color="action">home</Icon>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">iCRM</Typography>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">Manager</Typography>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">{t("ASSIGNNEWLEADS")}</Typography>
                            </div>
                            <Typography variant="h6">{t("ASSIGNNEWLEADS")}</Typography>
                        </div>
                    <div className="flex items-end">
                        <FormControl className="p-12">
                            <InputLabel id="land-label">{t("COMPANY")}</InputLabel>
                            <Select
                                id="land-select"
                                value={this.state.filterNewleads}
                                onChange={(event) => this.setFilterNewleads(event.target.value)}
                            >
                                {companylist.map(row => (
                                    <MenuItem key={row.key} value={row.key}>{row.value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Tooltip title={t("EDIT") + " Favorite"}>
                            <Button className="successButton p-24" onClick={this.handleFavoritesDialogOpen}>
                                <Icon>star</Icon>
                            </Button>
                        </Tooltip>
                    </div>
                    </div>
                }
                content={
                    <FuseAnimateGroup animation="transition.slideUpIn" delay={300}>
                        <div className="flex flex-wrap w-full">
                            <ReactTable
                                className="w-full -striped -highlight border-0"
                                data={this.getFinalData()}
                                // data={assignnewleads}
                                columns={[
                                    {
                                        Header    : t("VALIDATE"),
                                        accessor  : "validated",
                                        className: "justify-center",
                                        width     : 50,
                                        Cell: row => row.original.validated === true ?
                                        <Tooltip title={t("VALIDATE")}>
                                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                                        </Tooltip>
                                        :
                                        <>
                                        {row.original.azurE_ID === null && row.original.manageR_AZURE_ID === 'RETURNED' ?
                                        <Tooltip 
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit">{'Lead was sent back by account manager'}</Typography>
                                            </React.Fragment>
                                            }>
                                            <Icon color="action" style={{color: 'orange'}}>settings_backup_restore</Icon>
                                        </Tooltip>
                                        : 
                                        <Tooltip title={t("VALIDATE")}>
                                            <Icon className="text-18" color="error">clear</Icon>
                                        </Tooltip>
                                        }
                                        </>
                                    },
                                    {
                                        Header    : "Co",
                                        accessor  : "companyid",
                                        filterable: true,
                                        className: "justify-center",
                                        width: 50,
                                        Cell: row =>                                         
                                        row.value === 1 ? 
                                        <Tooltip title={"Van der Plas Flowers & Plants"}>
                                            <Typography color="inherit">VDP</Typography>
                                        </Tooltip>
                                        :
                                        row.value === 2 ? 
                                        <Tooltip title={"Dexx Drive"}>
                                            <Typography color="inherit">DD</Typography>
                                        </Tooltip>
                                        :
                                        row.value === 3 ? 
                                        <Tooltip title={"Greenflor"}>
                                            <Typography color="inherit">GF</Typography>
                                        </Tooltip>
                                        :
                                        row.value === 4 ? 
                                        <Tooltip title={"Heembloemex Deco"}>
                                            <Typography color="inherit">HBX Deco</Typography>
                                        </Tooltip>
                                        :
                                        row.value === 5 ? 
                                        <Tooltip title={"Green & Bloom"}>
                                            <Typography color="inherit">GB</Typography>
                                        </Tooltip>
                                        :
                                        <Tooltip title={"Error contact icrm@floraltradegroup.com"}>
                                            <Typography style={{color: 'orange'}}>Error</Typography>
                                        </Tooltip>
                                    },
                                    {
                                        Header    : "Email",
                                        accessor  : "email",
                                        filterable: true,
                                        // className: "justify-center",
                                        // width: 180,
                                        Cell: row => row.original.acctmngr !== null ?
                                        <Tooltip title={'Already an ' + t("ACCOUNTMNGR") + ' in Sendinblue or Florisoft'}>
                                            <Typography style={{color: 'orange'}}>{row.value}</Typography>
                                        </Tooltip>
                                        :
                                        row.value
                                    },
                                    {
                                        Header    : t("SHOP") + " " + t("NAME"),
                                        accessor  : "debnaam",
                                        filterable: true,
                                        className : "font-bold",
                                    },
                                    {
                                        Header    : t("COUNTRY"),
                                        accessor  : "deblandcod",
                                        className: "justify-center",
                                        filterable: true,
                                        width: 120,
                                    },
                                    {
                                        Header    : t("CITY"),
                                        accessor  : "debplaats",
                                        className: "justify-center",
                                        filterable: true,
                                        // width: 180,
                                        // Cell: row => row.value,
                                    },
                                    {
                                        Header    : t("ZIPCODE"),
                                        accessor  : "debpc",
                                        className : "justify-center",
                                        filterable: true,
                                        width: 80,
                                        // Cell: row => row.value,
                                    },
                                    {
                                        Header    : t("CREATED"),
                                        accessor  : "createdat",
                                        className : "justify-center",
                                        filterable: true,
                                        width: 120,
                                        // Cell: row => moment(row.value).format('DD/MM/YYYY'),
                                        Cell: row => (row.original.listids) ?
                                        <>{moment(row.value).format('DD/MM/YYYY')}</>
                                        :
                                        <>{moment(row.original.icrM_CREATED).format('DD/MM/YYYY')}</>
                                    },
                                    // {
                                    //     Header    : t("ACCOUNTMNGR"),
                                    //     accessor  : "acctmngr",
                                    //     className: "justify-center",
                                    //     width: 1,
                                    //     Cell: row => "",
                                    // },
                                    {
                                        Header    : "SIB",
                                        accessor  : "debtext",
                                        filterable: true,
                                        className: "justify-center",
                                        width: 70,
                                        Cell: row => row.original.debtext === '' || row.original.debtext === null || row.original.debtext === undefined ? 
                                        null 
                                        : 
                                        <Tooltip title={row.value}>
                                                <IconButton aria-label="Comment">
                                                    <Icon>comment</Icon>
                                                </IconButton>
                                        </Tooltip>
                                    },
                                    {
                                        Header    : t("NOTE"),
                                        accessor  : "acctmngR_NOTE",
                                        filterable: true,
                                        className: "justify-center",
                                        width: 70,
                                        Cell: row => row.original.acctmngR_NOTE === '' || row.original.acctmngR_NOTE === undefined ? 
                                        null 
                                        : 
                                        (
                                        <Tooltip title={row.value}>
                                            <IconButton aria-label="Comment">
                                                <Icon>comment</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        )
                                    },
                                    {
                                        Header    : "Source",
                                        accessor  : "forM_SOURCE",
                                        filterable: true,
                                        className: "justify-center",
                                        width: 70,
                                        Cell: row => row.original.forM_SOURCE === '' || row.original.forM_SOURCE === null || row.original.forM_SOURCE === undefined ? 
                                        null 
                                        : 
                                        (
                                        <Tooltip title={'Source form : ' + row.value}>
                                            <IconButton aria-label="Comment">
                                                <Icon>comment</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        )
                                    },
                                    {
                                        Header    : t("ACCOUNTMNGR"),
                                        accessor  : "acctmngr",
                                        className: "justify-center",
                                        width     : 50,
                                        Cell: row => row.value !== null ?
                                        <Tooltip title={t("ACCOUNTMNGR") + ' : '+ row.value}>
                                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                                        </Tooltip>
                                        :
                                        <>
                                        {row.original.azurE_ID === null && row.original.manageR_AZURE_ID === 'RETURNED' ?
                                        <Tooltip 
                                        title={
                                            <React.Fragment>
                                                <Typography color="inherit">{row.original.acctmngR_NOTE}</Typography>
                                                <em></em>
                                            </React.Fragment>
                                            }>
                                            <IconButton aria-label="Comment">
                                                <Icon>comment</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        : 
                                        <Tooltip title={'Already an ' + t("ACCOUNTMNGR") + ' in Sendinblue or Florisoft'}>
                                            <Icon className="text-18" color="error">clear</Icon> 
                                        </Tooltip>
                                        }
                                        </>
                                    },
                                    {
                                        Header    : t("ACCOUNTMNGR"),
                                        accessor  : "icrmuser",
                                        className : "justify-center",
                                        filterable: true,
                                        width: 180,
                                        // Cell: row => row.original.listids ?
                                        Cell: row => (row.original.listids && row.original.validated === true) ?
                                        <Tooltip title={t("VALIDATE")}>
                                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                                        </Tooltip>
                                        :
                                        (
                                            favicrmusersnewleads.length > 1 ?
                                            <FormControl variant="outlined" fullWidth>
                                            <Select
                                                input={
                                                <OutlinedInput
                                                    labelWidth={0}
                                                />}
                                                id={"icrmuser"}
                                                name={"icrmuser"}
                                                value={row.value}
                                                onChange={(event) => this.handleChange(event, row.original)}
                                                required
                                                fullWidth
                                                defaultValue={''}
                                            >
                                                {favicrmusersnewleads.map(i => <MenuItem key={i.favoriteAzureId} value={i.favoriteAzureId}>{i.name}</MenuItem>)}
                                            </Select>
                                            </FormControl>
                                            : 
                                            <Tooltip title={"You didn't select any users, please select your favorite account managers"}>
                                                <Button className="successButton p-24" onClick={this.handleFavoritesDialogOpen}>
                                                    <Icon>star</Icon>
                                                </Button>
                                            </Tooltip>
                                        ),
                                    },
                                    {
                                        Header    : t("VALIDATE"),
                                        className : "justify-center",
                                        filterable: true,
                                        width: 80,
                                        // Cell: row => row.original.listids.includes(157) === true ?
                                        Cell: row => row.original.validated === true ?
                                        <Tooltip title={t("VALIDATE")}>
                                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                                        </Tooltip>
                                        :
                                        (
                                        <Button variant="outlined" onClick={() => this.onActivateNewLead(row.original)} disabled={!this.canBeSubmitted(row.original)}>
                                            <Icon className="text-18" color="action">send</Icon>
                                        </Button>
                                        ),
                                    },
                                ]}
                                pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                defaultPageSize={20}
                                noDataText="No customers found"
                            />
                        </div>
                    </FuseAnimateGroup>
                }
            />
            </>
            );

    }
}

const mapStateToProps = state => {
    return {
        assignnewleads: state.manager.assignnewleads,
        fetchedAssignNewLeads: state.manager.fetchedAssignNewLeads,
        returnednewLeads: state.manager.returnednewLeads,
        fetchedReturnedNewLeads: state.manager.fetchedReturnedNewLeads,
        icrmusersnewleads: state.manager.icrmusersnewleads,
        favicrmusersnewleads: state.manager.favicrmusersnewleads,
        isActivated: state.manager.isActivated
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchAssignNewLeads: () => dispatch(actions.fetchAssignNewLeads()),
        onFetchReturnedNewLeads: () => dispatch(actions.fetchReturnedNewLeads(getAzureId())),
        onFetchiCRMusersNewLeads: () => dispatch(actions.fetchiCRMusersNewLeads()),
        onFetchFaviCRMusersNewLeads: () => dispatch(actions.fetchFaviCRMusersNewLeads()),
        onActivateNewLead: (row, callback) => dispatch(actions.activateNewLead(row, callback)),
        onDeleteFavUser: user => dispatch(actions.deleteFaviCRMUser(user)),
        onAddFavUser: user => dispatch(actions.addFaviCRMUser(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation()(AssignNewLeads)));
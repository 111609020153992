import React, { Component } from 'react';
import { withStyles, Icon, Typography, Tooltip, IconButton, FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core';
import {connect} from 'react-redux';
import {FusePageSimple, FuseAnimateGroup} from '../../../../../@fuse';
import * as actions from '../../../../store/crm/manager/actions';
import ReactTable from "react-table";
// import RouteToDebnr from '../../../../components/Functions/RouteToDebnr';
// import AssignNewLeadsUserDialog from './AssignNewLeadsUserDialog';
import {withTranslation} from 'react-i18next';
// import _, { isUndefined } from 'lodash';
// import moment from 'moment';
// import { getAzureId } from '../../../Auth/Auth';
import moment from 'moment';

const styles = theme => ({

});

class OverviewNewLeads extends Component {

    state = {
        assignNewLeads: [],
        returnedNewLeads: [],
        iCRMusersNewLeads: [],
        FaviCRMusersNewLeads: [],
        insertedData: false,
        favoritesDialogOpen: false,
        fetchedReturnedNewLeads: false,
        getFinalData: [],
        filterNewleads: 'all'
        // filterNewleads: getCompany().toString()
    }


    componentDidMount() {
        this.props.onFetchOverviewNewLeads();
    }

    setFilterNewleads = (value) => {
        const {overviewnewleads} = this.props;
        this.setState({filterNewleads: value});
        this.setState({assignNewLeads: overviewnewleads});
    }
    getFinalData = () => {
        const { filterNewleads} = this.state;
        const {overviewnewleads} = this.props;
       
        if(filterNewleads === 'all') {
            return overviewnewleads;
        }
        else if(filterNewleads === '1') {
            return overviewnewleads.filter(newLead => newLead.company_id === 1);
        }
        else if(filterNewleads === '2') {
            return overviewnewleads.filter(newLead => newLead.company_id === 2);
        }
        else if(filterNewleads === '3') {
            return overviewnewleads.filter(newLead => newLead.company_id === 3);
        }
        else if(filterNewleads === '4') {
            return overviewnewleads.filter(newLead => newLead.company_id === 4);
        }
        else if(filterNewleads === '5') {
            return overviewnewleads.filter(newLead => newLead.company_id === 5);
        }
    }

    //  FiltermapStateToProps = ({state,val}) => {
    //      console.log(state.manager.overviewnewleads)
    //      console.log(val)
    //     // return {
    //     //     overviewnewleads: state.manager.overviewnewleads,
    //     // }
    // }
    
    render() {
        const {t} = this.props;

        const companylist = [
            {key: 'all', value: 'All'},
            {key: '1', value: 'VDP'},
            {key: '2', value: 'DEXX Drive'},
            {key: '3', value: 'GF'},
            {key: '4', value: 'HBX DECO'},
            {key: '5', value: 'GB'}
        ]
        
        // var icrmusers = [
        //     {"azureId":"8172c618-69ca-4d09-908c-b7d1b5d31710","name":"iCRM Jo10 Coslosquet"},
        //     {"azureId":"8172c618-69ca-4d09-908c-b7d1b5d31720","name":"iCRM Pierre20 Michel"}
        // ];

        return (
            <>
            <FusePageSimple
                header={
                    <div className="flex flex-1 items-center justify-between p-24">
                        <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                <Icon className="text-18" color="action">home</Icon>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">iCRM</Typography>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">Manager</Typography>
                                <Icon className="text-16" color="action">chevron_right</Icon>
                                <Typography color="textSecondary">{t("LEADS") + ' ' + t("OVERVIEW")}</Typography>
                            </div>
                            <Typography variant="h6">{t("LEADS") + ' ' + t("OVERVIEW")}</Typography>
                        </div>
                        <div className="flex items-end">
                        <FormControl className="p-12">
                            <InputLabel id="land-label">{t("COMPANY")}</InputLabel>
                            <Select
                                id="land-select"
                                value={this.state.filterNewleads}
                                onChange={(event) => this.setFilterNewleads(event.target.value)}
                            >
                                {companylist.map(row => (
                                    <MenuItem key={row.key} value={row.key}>{row.value}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Tooltip title={t("EDIT") + " Favorite"}>
                            <Button className="successButton p-24" onClick={this.handleFavoritesDialogOpen}>
                                <Icon>star</Icon>
                            </Button>
                        </Tooltip>
                    </div>
                    </div>
                }
                content={
                    <FuseAnimateGroup animation="transition.slideUpIn" delay={300}>
                        <div className="flex flex-wrap w-full">
                            <ReactTable
                                className="w-full -striped -highlight border-0"
                                data={this.getFinalData()}
                                // rowHeight={80} 
                                columns={[
                                    {
                                        Header    : "Co",
                                        accessor  : "company_id",
                                        filterable: true,
                                        className: "justify-center",
                                        width: 60,
                                        Cell: row => row.value === 2 ? 
                                        <Tooltip title={"Dexx Drive"}>
                                            <Typography color="inherit">DD</Typography>
                                        </Tooltip>
                                        :
                                        row.value === 3 ? 
                                        <Tooltip title={"Greenflor"}>
                                            <Typography color="inherit">GF</Typography>
                                        </Tooltip>
                                        :
                                        row.value === 4 ? 
                                        <Tooltip title={"Heembloemex Deco"}>
                                            <Typography color="inherit">HBX Deco</Typography>
                                        </Tooltip>
                                        :
                                        row.value === 5 ? 
                                        <Tooltip title={"Green & Bloom"}>
                                            <Typography color="inherit">GB</Typography>
                                        </Tooltip>
                                        :
                                        <Tooltip title={"Van der Plas Flowers & Plants"}>
                                            <Typography color="inherit">VDP</Typography>
                                        </Tooltip>
                                    },
                                    {
                                        Header    : t("CREATED"),
                                        accessor  : "icrM_CREATED",
                                        filterable: true,
                                        className : "font-bold",
                                        width: 200,
                                        Cell: row => moment(row.value).format('DD-MM-YYYY @ HH:mm'),
                                    },
                                    {
                                        Header    : t("VALIDATED") + " " + t("BY"),
                                        accessor  : "name",
                                        filterable: true,
                                        // className: "justify-center",
                                        width: 180,
                                        // Cell: row => row.original.manageR_NAME === null ? 
                                        // row.value 
                                        // : 
                                        // null
                                        // ,
                                    },
                                    {
                                        Header    : t("CUSTOMER") + " Email",
                                        accessor  : "email",
                                        // className: "left",
                                        filterable: true,
                                        width: '20%',
                                        // Cell: row => (
                                        //     <span onClick={() => this.handleEdit(row.original.email)}>
                                        //         {row.original.email}
                                        //     </span>
                                        // )
                                    },
                                    {
                                        Header    : t("ASSIGNED") + " " + t("BY"),
                                        accessor  : "manageR_NAME",
                                        filterable: true,
                                        // className: "justify-center",
                                        width: 180,
                                    },
                                    {
                                        Header    : t("NOTE"),
                                        accessor  : "acctmngR_NOTE",
                                        className: "justify-center",
                                        width: 70,
                                        Cell: row => row.original.acctmngR_NOTE === '' ? 
                                        null 
                                        : 
                                        (
                                        <Tooltip title={row.value}>
                                            <IconButton aria-label="Comment">
                                                <Icon>comment</Icon>
                                            </IconButton>
                                        </Tooltip>
                                        )
                                    },
                                    {
                                        Header    : "Template",
                                        accessor  : "template",
                                        filterable: true,
                                        className: "justify-center",
                                        width: 100,
                                        Cell: row => row.value === "" || row.value === null ? 
                                        <Tooltip title={t('CUSTOMER') + " template"}>
                                            <Icon className="text-18" color="error">clear</Icon> 
                                        </Tooltip>
                                        :
                                        row.value === "ARCHIVE" ? 
                                        <Tooltip title={"archived on : " + moment(row.original.validateD_DATE).format('DD-MM-YYYY @ HH:mm')}>
                                            <Icon className="text-18" style={{color: 'orange'}}>archive</Icon>
                                        </Tooltip>
                                        :
                                        <Tooltip title={"Template : " + row.value}>
                                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                                        </Tooltip>
                                    },
                                    {
                                        Header    : "Account mngr",
                                        accessor  : "acctmngR_VALIDATED",
                                        filterable: true,
                                        // filterAll: true,
                                        className: "justify-center",
                                        width: 100,
                                        Cell: row => row.value === 0 ? 
                                        <Tooltip title={row.value}>
                                            <IconButton aria-label="Account mngr">
                                                <Icon className="text-18" color="error">clear</Icon> 
                                            </IconButton>
                                        </Tooltip>
                                        :
                                        <Tooltip title={"Validated on : " + moment(row.original.validateD_DATE).format('DD-MM-YYYY @ HH:mm')}>
                                            <IconButton aria-label="Account mngr">
                                                <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    },
                                    {
                                        Header    : t("EXPORTED"),
                                        accessor  : "fS_EXPORTED",
                                        filterable: true,
                                        className: "justify-center",
                                        width: 100,
                                        Cell: row => row.value === 0 ? 
                                        <Tooltip title={"Exported : No"}>
                                            <Icon className="text-18" color="error">clear</Icon> 
                                        </Tooltip>
                                        :
                                        <Tooltip title={"Exported on : " + moment(row.original.fS_EXPORTED_DATE).format('DD-MM-YYYY @ HH:mm')}>
                                            <Icon className="text-18" style={{color: 'green'}}>check</Icon>
                                        </Tooltip>
                                    },
                                ]}
                                pageSizeOptions={[5, 10, 20, 50, 100, 500]}
                                defaultPageSize={100}
                                noDataText="No customers found"
                            />
                        </div>
                    </FuseAnimateGroup>
                }
            />
            </>
            );

    }
}

const mapStateToProps = state => {
    // this.FiltermapStateToProps(state)
    return {
        overviewnewleads: state.manager.overviewnewleads,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchOverviewNewLeads: () => dispatch(actions.fetchOverviewNewLeads())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withTranslation()(OverviewNewLeads)));
// @noflow
import React, {Component} from 'react';
import {FusePageSimple, FuseAnimateGroup} from '../../../../@fuse';
import * as actions from '../../../store/specialoffer/actions';
import { Button, Card, CardContent, Icon, IconButton, List, ListItem, ListItemText, 
    Typography, Tooltip, LinearProgress, CardHeader, Avatar, Divider, Box} from '@material-ui/core';
import {connect} from 'react-redux';
// import {getUserEmail} from '../../Auth/Auth';
import Alert from '../../../components/UI/Alert/Alert';
import { Alert as UIAlert, AlertTitle } from '@material-ui/lab';
import axios from 'axios';
import Skeleton from './SpecialOfferSkeleton';
import { Scrollbars } from 'react-custom-scrollbars';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import moment from 'moment';

const LinearDeterminateTimer = () => {
    return new Promise(res => setTimeout(res, 200));
}

class SpecialOffers extends Component {

    state = {
        specialOfferSent: {},
        startedSending: false,
        sendingFinished: false,
        percentage: 0,
        progress: 0,
        setProgress: 0,
        status: 0,
        errMessage: '',
        specialOffers: [],
        insertedSpecialOffers: false,
    }

    componentDidMount() {
        this.props.onFetchProducts();
        this.props.onFetchLatestSIBStatusUpdate();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.specialOfferProducts.length !== 0 && !this.state.insertedSpecialOffers &&
            this.props.specialOfferProducts.length !== this.state.specialOffers.length) {
                this.setState({specialOffers: this.props.specialOfferProducts, insertedSpecialOffers: true});
        } else if (this.state.specialOffers.length !== 0 && this.props.specialOfferProducts.length === 0 && this.state.insertedSpecialOffers) {
            this.setState({specialOffers: [], insertedSpecialOffers: false});
        }
    }

    onImageError = (spIndex, productIndex, product) => {
        const {specialOffers} = this.state;
        const newProduct = {...product, photoUrl: product.artikelPhoto};
        specialOffers[spIndex]['products'][productIndex] = newProduct;
        this.setState({specialOffers});
    }

    onSecondImageError = (spIndex, productIndex, product) => {
        const {specialOffers} = this.state;
        product.artikelPhoto = product.artikelPhoto.slice(0, -7) + 'V_1.jpg';
        const newProduct = {...product, photoUrl: product.artikelPhoto};
        specialOffers[spIndex]['products'][productIndex] = newProduct;
        this.setState({specialOffers});
    }

    onAllImageError = (spIndex, productIndex) => {
        const {specialOffers} = this.state;
        const products = [...specialOffers[spIndex]['products']];
        products.splice(productIndex, 1);
        this.setState({specialOffers});
    }

    // 1 : First Add and Remove users from the Sendinblue list
    addRemoveUsersSpecialOffer = specialOffer => {
        // Copy specialOffer from state into newOffer and edit the products array
        this.setState({specialOfferSent: specialOffer, startedSending: true, sendingFinished: false, status: 0, percentage: 0});
        var newOffer = {...specialOffer, products: specialOffer.products};
        this.props.onAddRemoveUsersSpecialOffer(newOffer, this.afterSendSpecialOffer, this.onSendError);

    }

    // 2 : After addRemoveUsers launch the progressbar
    afterSendSpecialOffer = (res, specialOffer) => {
        let customers = res.data;
        // const emails = customers.map(customer => customer.email);
        // const emailsToSend = [...new Set(emails)];
        this.LinearDeterminate(customers).then(() => {
            this.setState({status: res.status, sendingFinished: true, startedSending: false, percentage: 100});
            // console.log("Start sending Campaing now");
            this.sendCampaignSpecialOfferWithValutaConversion(specialOffer);
        })
    }

    // 2.1 : Progressbar
    LinearDeterminate = async (customers) => {
        let i = 0;
        if(customers.length > 0) {
            // let customers = 2200;
            let customersCount = customers.length;
            for(i = 0; i < (customersCount/14); i++) {
                await LinearDeterminateTimer();
                this.setState({percentage: i / (customersCount/14) * 100 })
                // wachten
            }
        }
    }
    
    // 3 : Create the SIB campaign and send it WithValutaConversion
    sendCampaignSpecialOfferWithValutaConversion = specialOffer => {
        var newOffer = {...specialOffer, products: specialOffer.products};
        // var newOffer2 = {...specialOffer.products, prices:{} };
        const transformProducts = (valutas) => {
            // let productsToSend = newOffer.products;
            return newOffer.products.map(product => {
                const allPrices = {};
                for(var valuta of valutas) {
                    allPrices[valuta.code] = (product.price * (100 / valuta.koers)).toFixed(2)
                }
                return {
                    ...product,
                    allPrices,
                    // valutaCode : valutaCode,
                    // priceValuta: (product.price * (100 / koers)).toFixed(2)
                };
                // return {...product, valutaCode : valutaCode, priceValuta: (product.price * (100 / koers)).toFixed(2)};
            });
        }
        axios.get('specialoffer/getvalutas').then(res => {
            const AllValuta = res.data;
            const productsToSend = transformProducts(AllValuta);
            newOffer.products = productsToSend;
            console.log(newOffer);
            this.props.onSendCampaignSpecialOffer(newOffer, this.onSendError);
        });
        // console.log(productsToSend);
    }

    // 3 : Create the SIB campaign and send it (old without valtua converstion unused)
    // sendCampaignSpecialOffer = specialOffer => {
    //     var newOffer = {...specialOffer, products: specialOffer.products};
    //     this.props.onSendCampaignSpecialOffer(newOffer, this.onSendError);
    // }
    
    onSendError = (err) => {
        // const rError = err.data.content
        // const objectError = JSON.parse(err.data);
        this.setState({status: err.status, errMessage: err.data});
        // this.setState({status: err.status, errMessage: objectError.message});
        //console.log('setting error');
    }

    render()
    {
        const {specialOffers} = this.state;
        const { statusUpdate } = this.props;
        // const userEmail = getUserEmail();
        // console.log(statusUpdate);

        return (
            <FusePageSimple
                header={
                      <div className="flex flex-1 items-center justify-between p-24">
                         <div className="flex flex-col">
                            <div className="flex items-center mb-16">
                                  <Icon className="text-18" color="action">home</Icon>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">iCRM</Typography>
                                  <Icon className="text-16" color="action">chevron_right</Icon>
                                  <Typography color="textSecondary">Special Offer</Typography>
                            </div>
                            <Typography variant="h6">Special Offer</Typography>
                         </div>
                         
                        <div className="flex flex-1 items-center justify-end px-12">
                            <IconButton onClick={this.props.onFetchProducts}>
                                    <Icon>refresh</Icon>
                            </IconButton>
                        </div>
                    </div>
                }
                content={
                    <>
                    
                    {
                        (statusUpdate && (statusUpdate.currentStatus === 'Degraded Performance' || statusUpdate.currentStatus === 'Service Disruption')) && <div className="pl-24 pt-24 pr-24">
                            <UIAlert className="items-center" severity={statusUpdate.currentStatus === 'Service Disruption' ? "error" : "warning"}>
                                <AlertTitle>{statusUpdate.title} - started {moment(statusUpdate.startDate).fromNow()}</AlertTitle>
                                {statusUpdate.details}
                            </UIAlert>
                        </div>
                    }
                        <div className="p-12">
                            <FuseAnimateGroup
                                className="flex flex-wrap"
                                enter={{
                                    animation: "transition.slideUpBigIn"
                                }}
                            >
                        {specialOffers && specialOffers.length > 0 ? specialOffers.filter(s => s.enabled)
                        .map((specialOffer, specialOfferIndex) => (
                            <div key={specialOffer.id} className="flex w-full sm:w-1/2 md:w-1/2 p-16 sm:p-12">
                                <Card className="w-full">
                                    <CardHeader 
                                        title={specialOffer.title}
                                        action={
                                        (specialOffer.senderEmail === '' ?
                                            <Button className="normal-case shadow-none px-16" disabled color="secondary" size="small" variant="contained">
                                                Send&nbsp;&nbsp;
                                                <Icon className="text-18" >send</Icon>
                                            </Button>
                                        :
                                        <>
                                            <Button className="normal-case shadow-none px-16" onClick={() => window.confirm('Are you sure you want to send?') && this.addRemoveUsersSpecialOffer(specialOffer)} color="secondary" size="small" variant="contained">
                                                Create + Send&nbsp;&nbsp;
                                                <Icon className="text-18" >send</Icon>
                                            </Button>
                                            {/* Test button to skip the Add and remove function */}
                                            {/* {<Button className="normal-case shadow-none px-16" onClick={() => this.sendCampaignSpecialOfferWithValutaConversion(specialOffer)} color="primary" size="small" variant="contained">
                                                Test button&nbsp;&nbsp;
                                                <Icon className="text-18" >sync</Icon>
                                            </Button>} */}
                                        </>
                                        )
                                        }
                                        >
                                    </CardHeader>
                                    {/* <Divider /> */}
                                    <CardHeader
                                        // style={{backgroundColor: '#3C4252'}}
                                        avatar={
                                        <Tooltip title={`Devisie: ${specialOffer.devisie} - Kenmerk: ${specialOffer.productType} - AB testing: ${specialOffer.abTesting}`}>
                                            <Avatar aria-label="recipe" className="background-color: #3C4252;">
                                                <Icon className="text-18" color="secondary">local_florist</Icon>
                                            </Avatar>
                                        </Tooltip>
                                        }
                                        title={"From : " + specialOffer.senderEmail + " " + specialOffer.senderName}
                                        // subheader={specialOffer.emailSubject}
                                        subheader={(specialOffer.emailSubject === '' || specialOffer.emailSubject === undefined || specialOffer.emailSubject === null) ?
                                            "Subject : none" : specialOffer.abTesting === false ? "Subject : " + specialOffer.emailSubject : 
                                                "Subject A : " + specialOffer.emailSubject + " & Subject B : " + specialOffer.emailSubjectB
                                            }
                                    />
                                    <Divider />
                                    <CardContent className="p-24">
                                    {/* {this.state.startedSending && !this.state.sendingFinished && this.state.specialOfferSent.id === specialOffer.id ? <LinearProgress color="secondary" variant="determinate" className="mb-12" value={this.state.percentage} /> : null} */}
                                    {this.state.startedSending && !this.state.sendingFinished && this.state.specialOfferSent.id === specialOffer.id && this.state.percentage === 0 ? <>
                                    <Box display="flex" alignItems="center">
                                        <Box width="100%" mr={1}>
                                            <LinearProgress color="primary" className="mb-12" />
                                        </Box>
                                        <Box minWidth={35}>
                                            <Typography variant="body2" color="textSecondary">{`${Math.round(0,)}%`}</Typography>
                                        </Box>
                                    </Box>
                                    </> : null}
                                    {this.state.startedSending && !this.state.sendingFinished && this.state.specialOfferSent.id === specialOffer.id  && this.state.percentage > 0 ? <>
                                    <Box display="flex" alignItems="center">
                                        <Box width="100%" mr={1}>
                                            <LinearProgress color="primary" variant="determinate" value={this.state.percentage} />
                                        </Box>
                                        <Box minWidth={35}>
                                            <Typography variant="body2" color="textSecondary">{`${Math.round(
                                            this.state.percentage,
                                            )}%`}</Typography>
                                        </Box>
                                    </Box>
                                    </> : null}
                                    {this.state.sendingFinished && this.state.status === 200 && this.state.specialOfferSent.id === specialOffer.id ? <Alert title="The Special Offer has been sent successfully" status="AlertSuccess" /> : null}
                                    {this.state.sendingFinished && this.state.status === 201 && this.state.specialOfferSent.id === specialOffer.id ? <Alert title="The Special Offer campaign has been created successfully" status="AlertSuccess" /> : null}
                                    {this.state.status === 500 && this.state.specialOfferSent.id === specialOffer.id ? <Alert title="Something went wrong!" status="AlertDanger" /> : null}
                                    {this.state.status === 400 && this.state.specialOfferSent.id === specialOffer.id ? <Alert title={this.state.errMessage} status="AlertDanger" /> : null}
                                    {this.state.status === 404 && this.state.specialOfferSent.id === specialOffer.id ? <Alert title={this.state.errMessage} status="AlertDanger" /> : null}
                                    {/* {this.state.alreadySent && this.state.specialOfferSent.id === specialOffer.id ? <Alert title="Already sent !" status="AlertSuccess" /> : null} */}
                                    {/* <LinearProgress color="secondary" variant="determinate" className="mb-12" value={this.state.percentage} /> */}
                                      
                                    {/* missing sender's Email */}
                                    {specialOffer.senderEmail === '' || specialOffer.senderName === '' ? <Alert title="Missing sender's Email and / or name !" status="AlertWarning" /> : null}
                                    {(specialOffer.emailSubject === '' || specialOffer.emailSubject === undefined || specialOffer.emailSubject === null) ? <Alert title="Missing Email subject !" status="AlertWarning" /> : null }
                                            <List className="p-0">
                                                <Scrollbars autoHeight autoHeightMin={100} autoHeightMax={560}>
                                                    {specialOffer.products.map((product, productIndex) => (                          
                                                    <ListItem key={product.productNumber + product.partijNumber} className="p-0">
                                                        <div style={{width: '70px', marginRight: '1.5rem' }}>
                                                            <LazyLoadImage 
                                                                src={product.photoUrl} 
                                                                onError={(e)=> { 
                                                                    if (product.artikelPhoto !== product.photoUrl && e.target.src !== product.artikelPhoto) {
                                                                        this.onImageError(specialOfferIndex, productIndex, product);
                                                                        e.target.src = product.artikelPhoto;
                                                                    } else if (product.artikelPhoto === product.photoUrl && e.target.src === product.photoUrl) {
                                                                        this.onSecondImageError(specialOfferIndex, productIndex, product);
                                                                    } else if (product.photoUrl.includes('_V_1')) {
                                                                        this.onAllImageError(specialOfferIndex, productIndex);
                                                                    }
                                                                }} 
                                                                alt={product.text} 
                                                                style={{maxHeight: '50px', maxWidth: '70px'}}
                                                                // placeholder={<Skeleton variant="rect" width={70} height={60} />}
                                                            />
                                                        </div>
                                                        <ListItemText
                                                                primary={(
                                                                        <div>
                                                                            <Typography className="inline font-medium" paragraph={false}>
                                                                                    {product.text}
                                                                            </Typography><br />

                                                                            <Typography className="inline" paragraph={false}>
                                                                                    Packaging: {product.amount}
                                                                            </Typography>
                                                                        </div>
                                                                )}
                                                                secondary={'€ ' + product.price.toFixed(2)}
                                                        />
                                                        {/* <ListItemSecondaryAction>
                                                            <IconButton>
                                                                    <Icon>more_vert</Icon>
                                                            </IconButton>
                                                        </ListItemSecondaryAction> */}
                                                    </ListItem>
                                                    ))}
                                                </Scrollbars>
                                            </List>
                                    </CardContent>
                                </Card>
                            </div>
                        )) : 
                        <>
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </>
                        }
                        </FuseAnimateGroup>
                    </div>
                    </>
                }
            />
        )
    }
}

const mapStateToProps = ({specialoffer}) => {
    return {
        specialOfferProducts: specialoffer.specialOffersProducts,
        emailsToSend: specialoffer.emailsToSend,
        statusUpdate: specialoffer.statusUpdate,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onFetchProducts: () => dispatch(actions.fetchSpecialOffersProducts()),
        onAddRemoveUsersSpecialOffer: (specialOffer, afterSendSpecialOffer, onSendError) => dispatch(actions.addRemoveUsersSpecialOffer(specialOffer, afterSendSpecialOffer, onSendError)),
        onSendCampaignSpecialOffer: (specialOffer, onSendError) => dispatch(actions.sendCampaignSpecialOffer(specialOffer, onSendError)),
        onFetchLatestSIBStatusUpdate: () => dispatch(actions.fetchLatestSIBStatusUpdate()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SpecialOffers);